import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import Const from '~/src/const'
import Logo from '~/assets/images/airregi_order_logo.svg'
import Sorthandle from '~/assets/images/sort_handle.svg'
import Trashbox from '~/assets/images/trash_box.svg'
import Success from '~/assets/images/success.svg'
import Help from '~/assets/images/help.svg'
import Next from '~/assets/images/next.svg'
import Check from '~/assets/images/check.svg'
import CircledCheck from '~/assets/images/circled_check.svg'
import CheckboxArrow from '~/assets/images/icon_checkbox_arrow.svg'
import Information from '~/assets/images/information.svg'
import Arrow from '~/assets/images/arrow.svg'
import Exclamation from '~/assets/images/exclamation.svg'
import ArrowDropDown from '~/assets/images/icon_arrow_dropdown.svg'
import Pencil from '~/assets/images/pencil.svg'
import Edited from '~/assets/images/edited.svg'
import DownArrow from '~/assets/images/Accordion/DownArrow.svg'
import FoldedDocument from '~/assets/images/folded_document.svg'
import ExternalLink from '~/assets/images/external_link.svg'
import ArrowRight from '~/assets/images/arrow_right.svg'
import TutorialIcon from '~/assets/images/TutorialIcon.svg'
import HandyApp from '~/assets/images/handyapp.svg'

const { Color } = Const

type Props = {
  id?: string
  name:
    | 'logo'
    | 'sort_handle'
    | 'trash_box'
    | 'success'
    | 'next'
    | 'check'
    | 'circled_check'
    | 'checkboxArrow'
    | 'arrowDropDown'
    | 'information'
    | 'arrow'
    | 'arrowRight'
    | 'pencil'
    | 'folded_document'
    | 'edited'
    | 'tutorialIcon'
    | 'handyApp'
}

const Icons: React.FC<Props> = props => {
  const Wrapper = Wrappers[props.name]
  return <Wrapper id={props.id} />
}

const Wrappers = {
  logo: styled(Logo)`
    width: 190px;
    height: 55px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    fill: #fff;
    .cls-2 {
      fill: ${Color.VIVID_BLUE};
    }
  `,
  tutorialIcon: styled(TutorialIcon)`
    vertical-align: middle;
    margin-right: 8px;
    margin-bottom: 2px;
  `,
  sort_handle: styled(Sorthandle)`
    width: 10px;
    height: 28px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    fill: ${Color.LIGHT_GRAY_5};
    cursor: move;
  `,
  trash_box: styled(Trashbox)`
    width: 18px;
    height: 21px;
    cursor: pointer;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    fill: ${Color.STRONG_BLUE};
    :hover {
      fill: ${Color.VIVID_BLUE};
    }
  `,
  success: styled(Success)`
    height: 76px;
    width: 76px;
    fill: ${Color.WHITE};
  `,
  next: styled(Next)`
    width: 10px;
    height: 30px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    fill: ${Color.LIGHT_GRAY_5};
    cursor: pointer;
  `,
  check: styled(Check)`
    width: 10px;
    height: 10px;
    vertical-align: middle;
    margin: 0 0 0 6px;
  `,
  circled_check: styled(CircledCheck)`
    width: 56px;
    height: 56px;
    fill: ${Color.LIGHT_GRAY_4};
  `,
  checkboxArrow: styled(CheckboxArrow)`
    width: 12px;
    height: 8px;
  `,
  arrowDropDown: styled(ArrowDropDown)`
    width: 10px;
    height: 6px;
  `,
  information: styled(Information)`
    width: 22px;
    height: 23px;
    margin-right: 8px;
    vertical-align: middle;
  `,
  arrow: styled(Arrow)`
    width: 22px;
    height: 22px;
  `,
  arrowRight: styled(ArrowRight)`
    width: 24px;
    height: 24px;
    fill: ${Color.STRONG_BLUE};
    vertical-align: middle;
  `,
  pencil: styled(Pencil)`
    height: 15px;
    width: 15px;
  `,
  folded_document: styled(FoldedDocument)`
    height: 56px;
    width: 56px;
    fill: ${Color.LIGHT_GRAY_4};
  `,
  edited: styled(Edited)`
    height: 15px;
    width: 15px;
    fill: ${Color.STRONG_BLUE};
  `,
  handyApp: styled(HandyApp)`
    height: 50px;
    width: 50px;
    fill: ${Color.VIVID_BLUE};
  `
}

export const DownArrowIcon = styled(DownArrow)`
  width: 10px;
  height: 10px;
  fill: ${Color.VERY_DARK_GRAY};
`

export const HelpIcon = styled(Help)<{ small: boolean }>`
  width: 20px;
  height: 20px;
  ${props =>
    props.small === 'true' &&
    `
    width: 16px;
    height: 16px;
  `}
`

export const ExternalLinkIcon = styled(ExternalLink)<{ color?: string }>`
  width: 12px;
  height: 12px;
  fill: ${props => (props.color ? props.color : Color.WHITE)};
`

type ExclamationColor = 'red' | 'orange'

export const ExclamationIcon = styled(Exclamation)<{ color: ExclamationColor }>`
  width: 18px;
  height: 16px;
  margin-right: 2px;
  vertical-align: middle;
  /* stylelint-disable-next-line declaration-colon-newline-after */
  fill: ${props => {
    switch (props.color) {
      case 'red':
        return Color.MODERATE_RED
      case 'orange':
        return Color.LIGHT_ORANGE
    }
  }};
`

export default Icons

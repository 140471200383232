import { ActionType, getType } from 'typesafe-actions'
import { actions } from './actions'
import { ItemBulkUploadState } from './types'

const initialState: ItemBulkUploadState = {
  isOpenSuccessModal: false,
  isOpenFailedModal: false,
  // エラー発生時に表示する
  returnCode: ''
}

export const itemBulkUpload = (
  state: ItemBulkUploadState = initialState,
  action: ActionType<typeof actions>
): ItemBulkUploadState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return {
        ...state,
        isOpenSuccessModal: false,
        isOpenFailedModal: false,
        returnCode: ''
      }
    }
    case getType(actions.openSuccessModal): {
      return {
        ...state,
        isOpenSuccessModal: true
      }
    }
    case getType(actions.closeSuccessModal): {
      return {
        ...state,
        isOpenSuccessModal: false
      }
    }
    case getType(actions.openFailedModal): {
      return {
        ...state,
        isOpenFailedModal: true,
        returnCode: action.payload.returnCode
      }
    }
    case getType(actions.closeFailedModal): {
      return {
        ...state,
        isOpenFailedModal: false,
        returnCode: ''
      }
    }

    default: {
      return state
    }
  }
}

import { Modal as AirKitModal } from '@air-kit/air-kit'
import React from 'react'
import styled from 'styled-components'
import Icon, { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import OBExampleImage from '~/assets/images/ItemsBulkUpload/OBExample.png'
import HandyExampleImage from '~/assets/images/ItemsBulkUpload/HandyExample.png'
import { datadogLogs } from '@datadog/browser-logs'
import { Datadog } from '~/src/const/datadog'
import Const from '~/src/const'

const { Size, Color } = Const

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const SuccessModal: React.VFC<Props> = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null
  }

  return (
    <Adjuster>
      <AirKitModal title="アップロード完了" onClose={onClose}>
        <Container>
          <Description>
            <DescriptionChunk>商品が一括登録されました。</DescriptionChunk>
            <DescriptionChunk>
              登録した商品は
              <RegiBOLink
                href="/#item"
                target="_blank"
                onClick={() => {
                  datadogLogs.logger.info(
                    Datadog.BROWSER_LOG_MESSAGE
                      .BULK_UPLOAD_COMPLETE_DIALOG_TO_ORD_BO.MESSAGE,
                    {
                      event:
                        Datadog.BROWSER_LOG_MESSAGE
                          .BULK_UPLOAD_COMPLETE_DIALOG_TO_ORD_BO.EVENT
                    }
                  )
                }}
              >
                Airレジ オーダー 管理画面
                <ExternalLinkIconWrapper>
                  <ExternalLinkIcon color={Color.VIVID_BLUE} />
                </ExternalLinkIconWrapper>
              </RegiBOLink>
              のメニューから編集できます。
            </DescriptionChunk>
          </Description>
          <BOImageContainer>
            <BOImageStyle src={OBExampleImage} alt="BOのサンプル画像" />
          </BOImageContainer>
          <FooterContainer>
            <FooterLeftContainer>
              <HandyIconContainer>
                <Icon name="handyApp" />
              </HandyIconContainer>
              <FooterTextContainer>
                <FooterMainText>アプリで確認してみましょう</FooterMainText>
                <FooterDescription>
                  登録した商品がスタッフ用注文アプリ「ハンディ」に反映されました。アプリをダウンロードして確認してみましょう。
                </FooterDescription>
              </FooterTextContainer>
            </FooterLeftContainer>
            <HandyImageContainer>
              <HandyImageStyle src={HandyExampleImage} alt="BOのサンプル画像" />
            </HandyImageContainer>
          </FooterContainer>
        </Container>
      </AirKitModal>
    </Adjuster>
  )
}

const Adjuster = styled.div`
  div[class^='feedback__Wrapper'] {
    top: ${Size.COMMON_HEADER_HEIGHT + Size.HEADER_HEIGHT}px;
  }
  div[class^='modal__ModalWrapper'] {
    max-width: 810px;
    width: calc(100vw - 100px);
    height: 543px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
`

const Description = styled.div`
  font-size: ${Size.FONT.LARGE};
`

const DescriptionChunk = styled.span`
  display: inline-block;
`

const RegiBOLink = styled.a`
  font-size: ${Size.FONT.LARGE};
  color: ${Color.VIVID_BLUE};
`

const ExternalLinkIconWrapper = styled.span`
  padding: 0 3px;
`

const BOImageContainer = styled.div`
  text-align: center;
  width: 100%;
  margin: 16px 0 40px 0;
`

const BOImageStyle = styled.img`
  width: 400px;
  height: 192px;
`

const FooterContainer = styled.div`
  background-color: ${Color.VERY_PALE_BLUE_2};
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 20px;
  width: 573px;
  height: 138px;
`

const FooterLeftContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
`

const FooterTextContainer = styled.div`
  line-height: 22px;
  height: 100%;
  padding: 20px 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FooterMainText = styled.p`
  margin: 0;
  color: ${Color.VIVID_BLUE};
`

const FooterDescription = styled.p`
  margin: 0;
`

const HandyImageContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`

const HandyImageStyle = styled.img`
  width: 100px;
  height: 100%;
`

const HandyIconContainer = styled.div``

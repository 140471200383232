const SITE = 'datadoghq.com'

const APPLICATION_ID = {
  DEVELOPMENT: 'ea968e11-9b32-4b21-92e7-7da3e58dbed3',
  PRODUCTION: '52a090bf-8763-4e4e-b1bf-4747e56f582a'
}

const CLIENT_TOKEN = {
  DEVELOPMENT: 'pub4e80858f6710bf25b32cfac81e10d712',
  PRODUCTION: 'pub6d4aaab86cf05b4bcca548c89802a68a'
}

const SERVICE_NAME = {
  DEVELOPMENT: 'dev-oes-bo-rum',
  PRODUCTION: 'prd-oes-bo-rum'
}

const CLIENT_TOKEN_BROWSER_LOGS = {
  DEVELOPMENT: 'pubbc721760e2a277ca27775bdd4956f1bd',
  PRODUCTION: 'pub0d542ab0a51ba28a5c8dafd01bca098d'
}

const SERVICE_NAME_BROWSER_LOGS = {
  DEVELOPMENT: 'dev-oes-bo-browser-log',
  PRODUCTION: 'prd-oes-bo-browser-log'
}

/**
 * データチームが使うブラウザログのメッセージ内容。
 * 追加する場合はコンフルページにも記載する。
 * https://one-recruit.atlassian.net/wiki/x/OlszXg
 */
const BROWSER_LOG_MESSAGE = {
  SPO_ORDER_LIMIT: {
    MESSAGE: 'Transition to SPO OrderLimit'
  },
  MATE: {
    MESSAGE: 'Transition to MATE',
    EVENT: 'transitionToMate'
  },
  SPO_UP_SELL_FROM_BANNER: {
    MESSAGE: 'Transition to SPO UpSell from Banner',
    EVENT: 'transitionToRSV'
  },
  SPO_UP_SELL_FROM_SIDE_MENU: {
    MESSAGE: 'Transition to SPO UpSell from SideMenu',
    EVENT: 'transitionToRSV'
  },
  RSV: {
    MESSAGE: 'Transition to RSV',
    EVENT: 'transitionToRSV'
  },
  BULK_UPLOAD_STORE_CONFIRM_DIALOG: {
    MESSAGE: 'show store confirm dialog',
    EVENT: 'showStoreConfirmDialog'
  },
  BULK_UPLOAD_STORE_CONFIRM_DIALOG_OK: {
    MESSAGE: 'click ok on the store confirm dialog',
    EVENT: 'clickOnTheStoreConfirmDialog'
  },
  BULK_UPLOAD_STORE_CONFIRM_DIALOG_LOGOUT: {
    MESSAGE: 'click logout on the store confirm dialog',
    EVENT: 'clickLogoutOnTheStoreConfirmDialog'
  },
  BULK_UPLOAD_ITEMS_TEXT_AREA: {
    MESSAGE: 'input text area on the items bulk upload',
    EVENT: 'inputTextAreaOnTheItemsBulkUpload'
  },
  BULK_UPLOAD_UPLOAD_CONFIRM_OK: {
    MESSAGE: 'click ok on the upload confirm',
    EVENT: 'clickOkOnTheUploadConfirm'
  },
  BULK_UPLOAD_UPLOAD_CONFIRM_CANCEL: {
    MESSAGE: 'click cancel on the upload confirm',
    EVENT: 'clickCancelOnTheUploadConfirm'
  },
  BULK_UPLOAD_ITEMS_BULK_UPLOAD_COMPLETE: {
    MESSAGE: 'items bulk upload is complete',
    EVENT: 'itemsBulkUploadIsComplete'
  },
  BULK_UPLOAD_ITEMS_BULK_UPLOAD_FAILURE: {
    MESSAGE: 'items bulk upload is failure',
    EVENT: 'itemsBulkUploadIsFailure'
  },
  BULK_UPLOAD_COMPLETE_DIALOG_TO_ORD_BO: {
    MESSAGE: 'Transition to ORD BO',
    EVENT: 'transitionToORDBO'
  }
}

export const Datadog = {
  APPLICATION_ID,
  CLIENT_TOKEN,
  SITE,
  SERVICE_NAME,
  CLIENT_TOKEN_BROWSER_LOGS,
  SERVICE_NAME_BROWSER_LOGS,
  BROWSER_LOG_MESSAGE
}

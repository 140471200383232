import React from 'react'
import { StepArea } from '../StepArea'
import styled from 'styled-components'
import getDataCodeImageSrc from '~/assets/images/ItemsBulkUpload/GetDataCode.png'

/**
 * ステップ１データコードを取得する
 */
export const Step1GetDataCode: React.FC = () => {
  return (
    <StepArea title="データコードを取得する" stepNumber={1}>
      <Step1ContentArea>
        <HowToGetDataCodeDescription>
          商品一括登録用シートの「データコードを取得する」ボタンをクリックしてデータコードをコピーしてください。
        </HowToGetDataCodeDescription>
        <HowToGetDataCodeImage src={getDataCodeImageSrc} />
      </Step1ContentArea>
    </StepArea>
  )
}

const Step1ContentArea = styled.div`
  width: 464px;
  display: flex;
  gap: 24px;
`
const HowToGetDataCodeDescription = styled.div`
  width: 226px;
`

const HowToGetDataCodeImage = styled.img`
  width: 214px;
`

import React from 'react'
import styled from 'styled-components'
import Modal from '~/src/components/organisms/Modal'

import Const from '~/src/const'

const { Size, Color } = Const

type Props = {
  isOpen: boolean
  onOkHandler: () => void
  onCloseHandler: () => void
}

export const UploadMenuConfirmModal: React.VFC<Props> = ({
  isOpen,
  onOkHandler,
  onCloseHandler
}) => {
  return (
    <Modal
      title={'商品一括登録用シートのアップロード'}
      closable
      isOpen={isOpen}
      onClose={onCloseHandler}
      onOK={onOkHandler}
      cancelText="キャンセル"
      okText="OK"
    >
      <Container>
        <Description>
          商品一括登録を行います。
          <br />
          <ColoredText>
            すでに登録済みの商品が入力されている場合は上書きされず二重登録されます。
          </ColoredText>
          よろしいですか？
        </Description>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Description = styled.p`
  font-size: ${Size.FONT.BASE}px;
  color: ${Color.VERY_DARK_GRAY_4};
  margin: 0;
`

const ColoredText = styled.span`
  color: ${Color.DARK_RED};
`

import React, { useMemo } from 'react'
import { StepArea } from '../StepArea'
import styled from 'styled-components'
import Const from '~/src/const'
import Button from '~/src/components/atoms/Button'
import ErrorExclamationMarkImageSrc from '~/assets/images/ItemsBulkUpload/ErrorExclamationMark.svg'
import { PostItemsBody } from '../types'
import { useValidation } from '../hooks'
import { Datadog } from '~/src/const/datadog'
import { datadogLogs } from '@datadog/browser-logs'

const { Color } = Const

type Props = {
  inputData: string
  setInputData: React.Dispatch<React.SetStateAction<string>>
  showUploadMenuConfirmModal: (e: React.MouseEvent<HTMLInputElement>) => void
  handleParsedData: (inputData?: PostItemsBody) => void | undefined
  parsedData: PostItemsBody | undefined
}
/**
 * ステップ２アップロードする
 */
export const Step2UploadDataCode: React.FC<Props> = ({
  inputData,
  setInputData,
  showUploadMenuConfirmModal,
  handleParsedData,
  parsedData
}) => {
  const { executeValidate, errorMessage } = useValidation(
    handleParsedData,
    parsedData
  )
  /**
   * テキストエリアでデータコードが変更されたら発火する関数
   * データコードをステートに保存しバリデーションを行う
   */
  const onChangeInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    datadogLogs.logger.info(
      Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_ITEMS_TEXT_AREA.MESSAGE,
      {
        event: Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_ITEMS_TEXT_AREA.EVENT
      }
    )
    setInputData(e.target.value)
    executeValidate(e.target.value)
  }

  /**
   * テキストエリアの内容を削除する
   */
  const clearDataCode = () => {
    setInputData('')
    executeValidate('')
  }

  const isDisableSubmit = useMemo(() => {
    return !!errorMessage || !inputData
  }, [errorMessage, inputData])

  return (
    <StepArea title="アップロードする" stepNumber={2}>
      <Step2ItemsBulkUploadForm>
        {errorMessage && (
          <InvalidJsonMessage>
            <div style={{ width: 20 }}>
              <ErrorExclamationMarkImageSrc />
            </div>
            {errorMessage}
          </InvalidJsonMessage>
        )}
        <ItemsBulkUploadTextArea
          name="inputItems"
          value={inputData}
          onChange={onChangeInputHandler}
          placeholder={
            'ここにデータコードを貼り付け\n※右クリックし「貼り付け」をクリックしてください'
          }
          style={{ border: errorMessage && `2px solid ${Color.DARK_RED}` }}
        />
        <UploadDetails>
          <NoteDoubleRegisteredBolder>
            すでに登録済みの商品が商品一括登録用シートに入力されていると、上書きされずに管理画面に二重登録されます。
          </NoteDoubleRegisteredBolder>
          登録した商品は管理画面からいつでも編集できます。
        </UploadDetails>
        <ItemsBulkUploadButtonsContainer>
          <Button
            primary
            disabled={isDisableSubmit}
            onClick={showUploadMenuConfirmModal}
          >
            アップロード
          </Button>
          <Button normal type="button" onClick={clearDataCode}>
            データコードをクリア
          </Button>
        </ItemsBulkUploadButtonsContainer>
      </Step2ItemsBulkUploadForm>
    </StepArea>
  )
}

const Step2ItemsBulkUploadForm = styled.form`
  background-color: ${Color.VERY_PALE_BLUE_2};
  display: flex;
  flex-direction: column;
  padding: 14px;
  border-radius: 12px;
  gap: 16px;
`

const InvalidJsonMessage = styled.div`
  color: ${Color.DARK_RED};
  display: flex;
  gap: 4px;
  font-size: 14px;
`

const ItemsBulkUploadTextArea = styled.textarea`
  height: 221px;
  border: 2px solid ${Color.VIVID_BLUE};
  resize: none;
  box-shadow: 0 0 5px ${Color.VIVID_BLUE};
  outline: none;
  border-radius: 4px;
  padding: 12px;
`

const UploadDetails = styled.div`
  font-size: 14px;
`

const NoteDoubleRegisteredBolder = styled.span`
  color: ${Color.VERY_DARK_GRAY_4};
  font-weight: bold;
  font-size: 14px;
`

const ItemsBulkUploadButtonsContainer = styled.div`
  padding-top: 8px;
  display: flex;
  gap: 16px;
`

export default {
  HOME: '/',
  SETTING: '/setting',
  SALES_DISPLAY_SETTING: '/sales_display_setting',
  ITEM: '/item',
  CATEGORY: '/category',
  ORDER_ITEM_MEMO_TEMPLATE: '/order_memo',
  SEAT_SET: {
    LIST: '/order_area',
    CREATE: '/order_area/create',
    UPDATE: (id = ':id') => `/order_area/${id}/update`
  },
  PRINTER_TARGET: {
    CATEGORIES: '/print_target/categories',
    BATCH_SELECT: (seatSetId = ':seatSetId') =>
      `/print_target/categories/batch_select/${seatSetId}`,
    ITEMS: (categoryId = ':categoryId') =>
      `/print_target/categories/${categoryId}/items`
  },
  STAFF: '/staff',
  DEVICE_COUNT: {
    _INDEX: '/device_count',
    UPDATE: '/device_count/update',
    CONFIRM: '/device_count/confirm'
  },
  REGISTERED_DEVICE: '/registered_device',
  TABLE: '/table',
  COURSE: {
    LIST: '/course',
    CREATE: '/course/create',
    DETAIL: (courseId = ':courseId') => `/course/detail/${courseId}`
  },
  HODAI: {
    LIST: '/hodai',
    CREATE: '/hodai/create',
    DETAIL: (hodaiId = ':hodaiId') => `/hodai/detail/${hodaiId}`
  },
  KITCHEN_POSITION: '/kitchen_position',
  LOGIN_ERROR: '/login_error',
  KITCHEN_POSITION_TARGET: '/kitchen_position_target',
  TARGET_SERVING_TIME: {
    SETTING: '/target_serving_time',
    OPTION_SETTING: '/target_serving_time/option_setting'
  },
  SKU_SETTING: '/sku_setting',
  VARIATION_GROUP: {
    STATE: '/variation_group',
    GETTING_STARTED: '/variation_group/getting_started',
    ITEMS: '/variation_group/items',
    CONFIRM: '/variation_group/confirm',
    DOWNLOADED: '/variation_group/downloaded'
  },
  TOPPING: {
    ITEM_BY_TOPPING_GROUP: (toppingGroupId = ':toppingGroupId') =>
      `/toppings/item_by_topping_group/${toppingGroupId}`,
    LIST: '/toppings',
    TOPPING_GROUP: {
      CREATE: '/toppings/topping_groups/create',
      EDIT: (toppingGroupId = ':toppingGroupId') =>
        `/toppings/topping_groups/${toppingGroupId}`,
      TARGETS: '/toppings/topping_group/:toppingGroupId/targets'
    }
  },
  REFERENCE_NUMBER: '/reference_number',
  OTOSHI: '/otoshi',
  DISCOUNT_SURCHARGE_SETTING: '/discount_surcharge_setting',
  BUSINESS_SUPPORT: '/business_support',
  SPO_UP_SELL: '/spo_promotion',
  ITEMS_BULK_UPLOAD: '/items_bulk_upload'
}

import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import Const from '~/src/const'
import { useCallback } from 'react'
import { clearLoading, setLoading } from '~/src/redux/modules/UI'
import { PostItemsBody } from './types'
import { actions } from './actions'
import { datadogLogs } from '@datadog/browser-logs'
import { Datadog } from '~/src/const/datadog'
import { handleErrorForCustomAction } from '~/src/redux/modules/ApiError'

const { API } = Const

/**
 * 商品一括登録するリクエストを作成
 * @param apiClient
 */
export const useItemsBulkUpload = (
  apiClient: ApiClientInterface = ApiClient
) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  return useCallback(
    async (postItemsBody: PostItemsBody) => {
      // loading表示
      dispatch(setLoading())

      return (
        apiClient
          .post(API.POST_ITEMS, postItemsBody)
          .then(() => {
            datadogLogs.logger.info(
              Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_ITEMS_BULK_UPLOAD_COMPLETE
                .MESSAGE,
              {
                event:
                  Datadog.BROWSER_LOG_MESSAGE
                    .BULK_UPLOAD_ITEMS_BULK_UPLOAD_COMPLETE.EVENT
              }
            )
            dispatch(actions.openSuccessModal())
          })
          .catch(error => {
            datadogLogs.logger.info(
              Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_ITEMS_BULK_UPLOAD_FAILURE
                .MESSAGE,
              {
                event:
                  Datadog.BROWSER_LOG_MESSAGE
                    .BULK_UPLOAD_ITEMS_BULK_UPLOAD_FAILURE.EVENT
              }
            )
            dispatch(
              handleErrorForCustomAction(
                error,
                actions.openFailedModal({
                  // この時点ではresponseがundefinedの可能性があるためオプショナルにしている
                  returnCode: error.response?.data?.returnCode
                })
              )
            )
          })
          // loading非表示
          .finally(() => dispatch(clearLoading()))
      )
    },
    [dispatch, apiClient]
  )
}

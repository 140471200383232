export default {
  WHITE: '#ffffff',
  VERY_LIGHT_GRAY_7: '#fdfdfd',
  VERY_LIGHT_GRAY_3: '#fcfcfc',
  VERY_LIGHT_GRAY: '#fafafa',
  VERY_LIGHT_GRAY_4: '#f4f4f4',
  VERY_LIGHT_GRAY_9: '#f0f0f0',
  VERY_LIGHT_GRAY_2: '#e0e0e0',
  VERY_LIGHT_GRAY_6: '#dddddd',
  LIGHT_GRAY: '#d8d8d8',
  LIGHT_GRAY_3: '#d1d1d1',
  LIGHT_GRAY_4: '#cccccc',
  LIGHT_GRAY_2: '#c7c7c7',
  LIGHT_GRAY_5: '#b2b2b2',
  GRAY: '#b4b4b4',
  GRAY_2: '#afafaf',
  GRAY_3: '#aaaaaa',
  DARK_GRAY_4: '#999999',
  DARK_GRAY_3: '#939393',
  DARK_GRAY_2: '#8b8b8b',
  DARK_GRAY: '#777777',
  VERY_DARK_GRAY: '#555555',
  VERY_DARK_GRAY_4: '#222222',
  VERY_DARK_GRAY_3: '#434343',
  VERY_DARK_GRAY_2: '#333333',
  BLACK_ALPHA10: 'rgba(0, 0, 0, 0.1)',
  BLACK_ALPHA50: 'rgba(0, 0, 0, 0.5)',
  BLACK: '#000000',
  VERY_DARK_GRAYPALE_BLUE: '#edf7ff',
  LIGHT_GRAYISH_BLUE: '#d6dde2',
  LIGHT_GRAYISH_BLUE_2: '#cdd5db',
  DARK_GRAYISH_BLUE_2: '#a0aab2',
  DARK_GRAYISH_CYAN_2: '#989EA0',
  DARK_GRAYISH_BLUE: '#899095',
  DARK_GRAYISH_BLUE_3: '#80888e',
  DARK_GRAYISH_BLUE_1: '#788083',
  VERY_DARK_GRAYISH_BLUE_7: '#697275',
  VERY_DARK_GRAYISH_BULE_6: '#505d65',
  VERY_DARK_GRAYISH_BLUE_1: '#47545A',
  VERY_DARK_GRAYISH_BLUE_3: '#29363b',
  VERY_PALE_BLUE: '#e5f4ff',
  VERY_PALE_BLUE_2: '#f0faff',
  VERY_SOFT_BLUE: '#92d7f2',
  SOFT_BLUE: '#63c6ed',
  VIVID_BLUE: '#20aee5',
  STRONG_BLUE: '#0892c7',
  MODERATE_BLUE: '#5eabca',
  DARK_MODERATE_BLUE: '#458aa5',
  DARK_BLUE: '#1679a0',
  VERY_DARK_BLUE: '#115e7d',
  LIME_GREEN: '#76c60e',
  VERY_PALE_RED: '#ffeeec',
  PALE_RED: '#ffa79f',
  PALE_RED_2: '#ffe7de',
  SOFT_RED: '#fc6356',
  MODERATE_RED: '#f55852',
  DARK_MODERATE_RED: '#c8473c',
  DARK_RED: '#e02412',
  VERY_PALE_ORANGE: '#ffedda',
  LIGHT_ORANGE: '#ff9700'
}

import React from 'react'
import styled from 'styled-components'
import { StepBadge } from '~/src/components/atoms/StepBadge'
import Const from '~/src/const'

const { Color, Weight } = Const

type Props = {
  stepNumber: number
  title: string
  children: React.ReactNode
}
/**
 * 各操作ステップのタイトルを含めた領域
 */
export const StepArea: React.FC<Props> = ({ stepNumber, title, children }) => {
  return (
    <StepContainer>
      <StepTitleArea>
        <StepBadge stepNum={stepNumber} />
        <Title>{title}</Title>
      </StepTitleArea>
      {children}
    </StepContainer>
  )
}

const StepTitleArea = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`

const Title = styled.div`
  color: ${Color.VERY_DARK_GRAY_4};
  font-size: 18px;
  padding-left: 8px;
  font-weight: ${Weight.BOLD};
  margin-top: auto;
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 464px;
`

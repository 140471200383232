import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SideMenu } from '~/src/components/organisms/SideMenu/SideMenu'
import ModalContainer from '~/src/components/organisms/ModalContainer'
import { Loading } from '~/src/components/organisms/Loading/Loading'
import { Toast } from '~/src/components/organisms/Toast/Toast'
import { InitialViewSplash } from '~/src/components/organisms/InitialViewSplash'
import { AirCommonHeader } from '~/src/components/organisms/AirCommonHeader'
import { OESHeader } from '~/src/components/organisms/Header'
import { useLocation } from 'react-router-dom'

import Const from '~/src/const'

const { Page } = Const

const AppTemplate: React.FC = props => {
  const pathname = useLocation().pathname
  // 商品一括登録画面でユーザーにサイドメニューを押されてに元の画面に戻れなくなることを防ぐためにサイドメニューを非表示にする
  const isShowSideMenu = useMemo(() => {
    return pathname !== Page.ITEMS_BULK_UPLOAD
  }, [pathname])
  return (
    <>
      <Container>
        <AirCommonHeader />
        <OESHeader />
        <PageContainer>
          {isShowSideMenu && <SideMenu />}
          {props.children}
        </PageContainer>
      </Container>
      <ModalWrapper>
        {/* IE11でZ-Indexが効かないためココに置いている */}
        <ModalContainer />
        <InitialViewSplash />
        <Loading />
        <Toast />
      </ModalWrapper>
    </>
  )
}

export default AppTemplate

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ModalWrapper = styled.div``

const PageContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  overflow-y: auto;
`

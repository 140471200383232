/**
 * API エラーハンドリング
 */

import Const from '~/src/const'
import Session from '~/src/util/Session'
import logger from '~/src/util/logger'
import { AxiosResponse, AxiosError } from 'axios'
import { PayloadAction, TypeConstant } from 'typesafe-actions'

const { Error, ReturnCode } = Const

// Action Types
const SET_ERROR = 'oes/api-error/SET_ERROR'
const CLEAR_ERROR = 'oes/api-error/CLEAR_ERROR'
const SET_UNAUTHORIZED = 'oes/api-error/SET_UNAUTHORIZED'
const HANDLE_FORCE_LOGOUT = 'oes/api-error/HANDLE_FORCE_LOGOUT'

// Actions
/**
 * メッセージとコードを受け取ってエラー状態にする.
 * @param {string} message エラーメッセージ
 * @param {string} code API 結果コード
 * @param {Error} error エラーオブジェクト
 */
const setError = (
  title?: string | null,
  message?: string | null,
  code?: string | number | null,
  e?: AxiosError
) => ({
  type: SET_ERROR,
  payload: { title, message, code, e }
})

/**
 * エラー状態をクリアする.
 */
export const clearError = () => ({
  type: CLEAR_ERROR
})

/**
 * 未認証状態フラグをセットする.
 */
const setUnauthorized = () => ({
  type: SET_UNAUTHORIZED
})

const handleForceLogout = (
  title?: string | null,
  message?: string | null,
  code?: string | number | null
) => ({
  type: HANDLE_FORCE_LOGOUT,
  payload: { title, message, code }
})

export const handleError = (error: AxiosError) => {
  logger.error(error)

  if (error.response && error.response.data && error.response.data.returnCode) {
    return handleApiError(error.response)
  } else {
    return handleHttpError(error)
  }
}

// APIエラーが発生した際に、独自のreduxアクションを実行したい場合に呼び出す関数
export const handleErrorForCustomAction = <TType extends TypeConstant>(
  error: AxiosError,
  customAction: PayloadAction<TType, unknown>
) => {
  logger.error(error)

  if (error.response && error.response.data && error.response.data.returnCode) {
    return customAction
  } else {
    return handleHttpError(error)
  }
}

/**
 * @param {Object} res Axios' response object
 */
const handleApiError = (response: AxiosResponse) => {
  const { title, returnCode, message } = response.data

  // リターンコードによってハンドリングしたいものだけ記載
  // 基本はサーバから返却されるタイトルとメッセージをそのまま使う
  switch (returnCode) {
    case ReturnCode.UNAUTHORIZED:
    case ReturnCode.PLF_TOKEN_EXPIRED:
      Session.login()
      return setUnauthorized()
    case ReturnCode.FORCE_LOGOUT:
      return handleForceLogout(title, message, returnCode)
    default:
      return setError(title, message, returnCode)
  }
}

/**
 * @param {Error} e Axios' error object
 */
const handleHttpError = (e: AxiosError) => {
  const { response } = e
  if (!response) {
    // response object がない場合はネットワークエラー
    // cf. https://github.com/axios/axios/issues/242
    return setError(Error.TITLE, Error.NETWORK_ERROR, null, e)
  }

  switch (response.status) {
    case 401:
    case 403:
      Session.login()
      return setUnauthorized()
    case 503:
      return setError(
        Error.SERVICE_UNAVAILABLE.TITLE,
        Error.SERVICE_UNAVAILABLE.MESSAGE
      )
    default:
      return setError(Error.TITLE, Error.UNDEFINED, null, e)
  }
}

export type ErrorInfo = {
  title?: string | null
  message?: string | null
  code?: string | null
}

type ApiErrorState = {
  isError: boolean
  isUnauthorized: boolean
  onClick?: () => void
  error: ErrorInfo
}

// Initial state
const initialState: ApiErrorState = {
  isError: false,
  isUnauthorized: false,
  onClick: undefined,
  error: {
    title: null,
    message: null,
    code: null
  }
}

// Reducer
// @ts-ignore
export default (state = initialState, action): ApiErrorState => {
  switch (action.type) {
    case SET_ERROR: {
      const { title, message, code } = action.payload
      return { ...state, isError: true, error: { title, message, code } }
    }
    case CLEAR_ERROR: {
      return { ...state, isError: false, onClick: undefined }
    }
    case SET_UNAUTHORIZED: {
      return {
        ...state,
        isUnauthorized: true
      }
    }
    case HANDLE_FORCE_LOGOUT: {
      const { title, message, code } = action.payload
      return {
        ...state,
        isError: true,
        error: { title, message, code },
        onClick: Session.login
      }
    }
    default:
      return state
  }
}

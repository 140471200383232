import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '~/src/components/organisms/Modal'
import SpreadSheetExampleImage from '~/assets/images/ItemsBulkUpload/SpreadSheetExample.png'

import Const from '~/src/const'
import { logout } from '~/src/util/Session'
import { datadogLogs } from '@datadog/browser-logs'
import { Datadog } from '~/src/const/datadog'

const { Size, Color, Weight } = Const

export const UploadStoreConfirmModal: React.VFC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  return (
    <Modal
      title={'アップロード先の店舗の確認'}
      closable
      isOpen={isOpen}
      onClose={() => {
        datadogLogs.logger.info(
          Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_STORE_CONFIRM_DIALOG_LOGOUT
            .MESSAGE,
          {
            event:
              Datadog.BROWSER_LOG_MESSAGE
                .BULK_UPLOAD_STORE_CONFIRM_DIALOG_LOGOUT.EVENT
          }
        )
        logout()
      }}
      onOK={() => {
        datadogLogs.logger.info(
          Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_STORE_CONFIRM_DIALOG_OK
            .MESSAGE,
          {
            event:
              Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_STORE_CONFIRM_DIALOG_OK
                .EVENT
          }
        )
        setIsOpen(false)
      }}
      cancelText="ログアウト"
      okText="OK"
    >
      <Container>
        <StoreInfo>
          ログイン店舗：
          <wbr />
          {APP.storeName}
        </StoreInfo>
        <Description>
          店舗を変更する場合はログアウトし <br />
          「アップロード画面に進む」ボタンから再アクセスしてください。
        </Description>
        <ImageContainer>
          <ImageStyle
            src={SpreadSheetExampleImage}
            alt="スプレッドシートの説明"
          />
        </ImageContainer>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StoreInfo = styled.p`
  font-size: ${Size.FONT.BASE}px;
  color: ${Color.VIVID_BLUE};
  font-weight: ${Weight.BOLD};
  word-break: keep-all;
  overflow-wrap: break-word;
  margin: 0;
`

const Description = styled.p`
  font-size: ${Size.FONT.BASE}px;
  color: ${Color.VERY_DARK_GRAY_4};
  margin: 0;
`

const ImageContainer = styled.div``

const ImageStyle = styled.img`
  width: 274px;
  height: 108px;
`

import React, { useCallback, useState } from 'react'
import { PostItemsBody } from './types'
import { useItemsBulkUpload } from './interactors'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { actions } from './actions'
import { useDispatch } from 'react-redux'
import { datadogLogs } from '@datadog/browser-logs'
import { Datadog } from '~/src/const/datadog'

/**
 * 商品設定スプシからコピーしたJSONをバリデーションする
 */
export const useValidation = (
  handleParsedData: (inputData?: PostItemsBody) => void,
  parsedData?: PostItemsBody
): {
  errorMessage: string
  executeValidate: (inputData: string) => void
} => {
  const [errorMessage, setErrorMessage] = useState<string>('')

  const executeValidate = useCallback((inputData: string) => {
    if (!inputData) {
      setErrorMessage('')
      return
    }
    try {
      if (!inputData) {
        handleParsedData(undefined)
        setErrorMessage('')
        return
      }

      if (parsedData) {
        throw new Error()
      }
      // 文字列をjsonに変換
      const json: PostItemsBody = JSON.parse(inputData)

      if (!json.data || !json.hash || parsedData) {
        throw new Error()
      }
      // Json正常時
      handleParsedData(json)
      setErrorMessage('')
    } catch (error) {
      // 型が異なる場合はエラー
      setErrorMessage(
        'このデータコードはアップロードできません。商品一括登録用シートに戻り再度データコードを取得してください。'
      )
      handleParsedData(undefined)
    }
  }, [])

  return { errorMessage, executeValidate }
}

export const useSuccessModal = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  const onCloseSuccessModal = useCallback(() => {
    dispatch(actions.closeSuccessModal())
  }, [])

  return { onCloseSuccessModal } as const
}

export const useFailedModal = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  const onCloseFailedModal = useCallback(() => {
    dispatch(actions.closeFailedModal())
  }, [])

  return { onCloseFailedModal } as const
}

export const useUploadConfirmModal = (parsedData?: PostItemsBody) => {
  const handlePostBulkItems = useItemsBulkUpload()
  const [isOpenUploadMenuConfirmModal, setIsOpenUploadMenuConfirmModal] =
    useState<boolean>(false)

  const onOpenHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault()
    setIsOpenUploadMenuConfirmModal(true)
  }

  const onOkHandler = async () => {
    datadogLogs.logger.info(
      Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_UPLOAD_CONFIRM_OK.MESSAGE,
      {
        event: Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_UPLOAD_CONFIRM_OK.EVENT,
        requestBody: parsedData
      }
    )
    if (!parsedData) {
      // この分岐には入らない
      return
    }

    await handlePostBulkItems(parsedData)
    setIsOpenUploadMenuConfirmModal(false)
  }

  const onCloseHandler = () => {
    datadogLogs.logger.info(
      Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_UPLOAD_CONFIRM_CANCEL.MESSAGE,
      {
        event:
          Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_UPLOAD_CONFIRM_CANCEL.EVENT
      }
    )
    setIsOpenUploadMenuConfirmModal(false)
  }

  return {
    isOpenUploadMenuConfirmModal,
    onOpenHandler,
    onOkHandler,
    onCloseHandler
  } as const
}

export const useInitialize = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  const initializeState = useCallback(() => {
    dispatch(actions.clearState())
  }, [])

  return { initializeState } as const
}

import { createAction } from 'typesafe-actions'

const CLEAR_STATE = 'oes/itemBulkUpload/CLEAR_STATE'
const OPEN_SUCCESS_MODAL = 'oes/itemBulkUpload/OPEN_SUCCESS_MODAL'
const CLOSE_SUCCESS_MODAL = 'oes/itemBulkUpload/CLOSE_SUCCESS_MODAL'
const OPEN_FAILED_MODAL = 'oes/itemBulkUpload/OPEN_FAILED_MODAL'
const CLOSE_FAILED_MODAL = 'oes/itemBulkUpload/CLOSE_FAILED_MODAL'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  openSuccessModal: createAction(OPEN_SUCCESS_MODAL)(),
  closeSuccessModal: createAction(CLOSE_SUCCESS_MODAL)(),
  openFailedModal: createAction(OPEN_FAILED_MODAL, ({ returnCode }) => ({
    returnCode
  }))(),
  closeFailedModal: createAction(CLOSE_FAILED_MODAL)()
}

import React from 'react'
import styled from 'styled-components'

import Modal from '~/src/components/organisms/Modal'

type Props = {
  isOpen: boolean
  onClose: () => void
  errorCode?: string | null
}

export const FailedModal = (props: Props) => {
  const { isOpen, onClose, errorCode } = props

  return (
    <Modal
      closable
      isOpen={isOpen}
      title={'アップロードに失敗しました'}
      onOK={() => onClose()}
      noUseCancelButton
    >
      <Description>
        詳しくは窓口にお問い合わせください
        <br />
        （エラーコード：{errorCode}）
      </Description>
    </Modal>
  )
}

const Description = styled.p`
  text-align: center;
`

import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'

import Analytics from '~/src/util/Analytics'

import AppTemplate from '~/src/components/templates/AppTemplate'

import AuthFilter from '~/src/components/organisms/AuthFilter'

import Category from './components/pages/Category'
import Item from './components/pages/Item'
import { OrderItemMemoTemplate } from './components/pages/OrderItemMemoTemplate'
import { CourseTop } from './pages/Course/CourseTop'
import { CourseSetting } from './pages/Course/CourseSetting'
import { TargetServingTime } from './pages/TargetServingTime/TargetServingTimeSetting/Top/Top'
import { OptionSetting } from './pages/TargetServingTime/OptionSetting/index'
import { HodaiTop } from './pages/Hodai/HodaiTop'
import { HodaiSetting } from './pages/Hodai/HodaiSetting'
import SeatSet from './components/pages/SeatSet'
import { PrintTargetByCategory } from './components/pages/PrintTarget/Categories'
import { PrintTargetByItem } from './components/pages/PrintTarget/Items'
import NoMatch from './components/pages/NoMatch'
import DeviceCount from './components/pages/DeviceCount'
import { RegisteredDevice } from './components/pages/RegisteredDevice'
import Staff from './components/pages/Staff'
import Table from './components/pages/Table'
import LoginError from './components/pages/LoginError'
import { Target } from './components/pages/KitchenPositionSetting/Target'
import { KitchenPosition } from './components/pages/KitchenPositionSetting/KitchenPosition'
import VariationGroup from './components/pages/VariationGroup'
import * as Topping from './components/pages/Topping'
import { ItemByToppingGroup } from './components/pages/Topping/ItemByToppingGroup/ItemByToppingGroup'
import { Setting } from './pages/Setting'
import { Otoshi } from './pages/Otoshi'
import { ReferenceNumber } from './pages/ReferenceNumber'
import { DiscountSurchargeSetting } from './pages/DiscountSurchargeSetting'
import { BusinessSupport } from './pages/BusinessSupport'
import { SpoUpSell } from './pages/SpoUpSell'
import { ItemsBulkUpload } from './pages/ItemsBulkUpload'

import Const from '~/src/const'

const { Page } = Const

// react-routerのrenderのフィルター
// サイカタを埋め込む時はこっちを使う
const analyticsFilter = Component => props => {
  Analytics.trackPageChange(props.match.path)
  return <Component {...props} />
}

export default () => (
  <HashRouter hashType="noslash">
    <Switch>
      {/* テンプレートを使わない場合はここにルーティング Login Error */}
      <Route path={Page.LOGIN_ERROR} render={analyticsFilter(LoginError)} />
      <Route component={AuthRequiredPages} />
    </Switch>
  </HashRouter>
)

// テンプレートとその内部のルーティング
const AuthRequiredPages = () => {
  return (
    <AuthFilter>
      <AppTemplate>
        <Switch>
          {/* Home */}
          <Route exact path={Page.HOME}>
            <Redirect to={Page.ITEM} />
          </Route>
          {/* Category */}
          <Route path={Page.CATEGORY} render={analyticsFilter(Category)} />
          {/* Item */}
          <Route path={Page.ITEM} render={analyticsFilter(Item)} />
          {/* Order Item Memo Template */}
          <Route
            path={Page.ORDER_ITEM_MEMO_TEMPLATE}
            render={analyticsFilter(OrderItemMemoTemplate)}
          />
          {/* SeatSet */}
          <Route
            path={Page.SEAT_SET.CREATE}
            render={analyticsFilter(SeatSet.Create)}
          />
          <Route
            path={Page.SEAT_SET.UPDATE()}
            render={analyticsFilter(SeatSet.Update)}
          />
          <Route
            path={Page.SEAT_SET.LIST}
            render={analyticsFilter(SeatSet.List)}
          />
          {/* Printer Target */}
          <Route
            path={Page.PRINTER_TARGET.ITEMS()}
            render={analyticsFilter(PrintTargetByItem)}
          />
          <Route
            path={Page.PRINTER_TARGET.BATCH_SELECT()}
            render={analyticsFilter(PrintTargetByCategory.BatchSelect)}
          />
          <Route
            path={Page.PRINTER_TARGET.CATEGORIES}
            render={analyticsFilter(PrintTargetByCategory.Categories)}
          />
          {/* Staff */}
          <Route path={Page.STAFF} render={analyticsFilter(Staff)} />
          {/* Registered Device */}
          <Route
            path={Page.REGISTERED_DEVICE}
            render={analyticsFilter(RegisteredDevice)}
          />
          {/* Table */}
          <Route path={Page.TABLE} render={analyticsFilter(Table)} />
          {/* courses */}
          <Route
            path={Page.COURSE.DETAIL()}
            render={analyticsFilter(CourseSetting)}
          />
          <Route
            path={Page.COURSE.CREATE}
            render={analyticsFilter(CourseSetting)}
          />
          <Route path={Page.COURSE.LIST} render={analyticsFilter(CourseTop)} />
          {/* Hodai */}
          <Route
            path={Page.HODAI.DETAIL()}
            render={analyticsFilter(HodaiSetting)}
          />
          <Route
            path={Page.HODAI.CREATE}
            render={analyticsFilter(HodaiSetting)}
          />
          <Route path={Page.HODAI.LIST} render={analyticsFilter(HodaiTop)} />
          {/* Device Count */}
          <Route
            path={Page.DEVICE_COUNT.CONFIRM}
            render={analyticsFilter(DeviceCount.Confirm)}
          />
          <Route
            path={Page.DEVICE_COUNT.UPDATE}
            render={analyticsFilter(DeviceCount.Modify)}
          />
          <Route
            path={Page.DEVICE_COUNT._INDEX}
            render={analyticsFilter(DeviceCount.Summary)}
          />
          {/* Kitchen Position */}
          <Route
            path={Page.KITCHEN_POSITION}
            render={analyticsFilter(KitchenPosition)}
          />
          <Route
            path={Page.KITCHEN_POSITION_TARGET}
            render={analyticsFilter(Target)}
          />
          {/* Variation Group */}
          <Route
            path={Page.VARIATION_GROUP.GETTING_STARTED}
            render={analyticsFilter(VariationGroup.GettingStarted)}
          />
          <Route
            path={Page.VARIATION_GROUP.ITEMS}
            render={analyticsFilter(VariationGroup.Items)}
          />
          <Route
            path={Page.VARIATION_GROUP.CONFIRM}
            render={analyticsFilter(VariationGroup.Confirm)}
          />
          <Route
            path={Page.VARIATION_GROUP.DOWNLOADED}
            render={analyticsFilter(VariationGroup.Downloded)}
          />
          {/* Target Serving Time */}
          <Route
            path={Page.TARGET_SERVING_TIME.OPTION_SETTING}
            render={analyticsFilter(OptionSetting)}
          />
          <Route
            path={Page.TARGET_SERVING_TIME.SETTING}
            render={analyticsFilter(TargetServingTime)}
          />
          {/* Topping Group */}
          <Route
            path={Page.TOPPING.ITEM_BY_TOPPING_GROUP()}
            render={analyticsFilter(ItemByToppingGroup)}
          />
          <Route
            exact
            path={Page.TOPPING.TOPPING_GROUP.CREATE}
            render={analyticsFilter(Topping.ToppingGroup)}
          />
          <Route
            path={Page.TOPPING.TOPPING_GROUP.EDIT()}
            render={analyticsFilter(Topping.ToppingGroup)}
          />
          <Route
            path={Page.TOPPING.LIST}
            render={analyticsFilter(Topping.ToppingList)}
          />
          {/* Reference Number */}
          <Route
            path={Page.REFERENCE_NUMBER}
            render={analyticsFilter(ReferenceNumber)}
          />
          <Route path={Page.OTOSHI} render={analyticsFilter(Otoshi)} />
          {/* Discount Surcharge Setting */}
          <Route
            path={Page.DISCOUNT_SURCHARGE_SETTING}
            render={analyticsFilter(DiscountSurchargeSetting)}
          />
          {/* Update Setting */}
          <Route path={Page.SETTING} render={analyticsFilter(Setting)} />
          {/* Business Support */}
          <Route
            path={Page.BUSINESS_SUPPORT}
            render={analyticsFilter(BusinessSupport)}
          />
          {/* ItemsBulkUpload */}
          <Route
            path={Page.ITEMS_BULK_UPLOAD}
            render={analyticsFilter(ItemsBulkUpload)}
          />
          <Route path={Page.SPO_UP_SELL} render={analyticsFilter(SpoUpSell)} />
          {/* no match */}
          <Route render={analyticsFilter(NoMatch)} />
        </Switch>

        {/* 特定の複数画面にまたがってStateを管理したいComponentはここより下に置くこと */}
        <Route
          path={Page.VARIATION_GROUP.STATE}
          component={VariationGroup.VariationGroupState}
        />
      </AppTemplate>
    </AuthFilter>
  )
}

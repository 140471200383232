import { AppState } from '~/src/redux/reducer'

export const selectIsOpenSuccessModal = (state: AppState) =>
  state.itemBulkUpload.isOpenSuccessModal

export const selectIsOpenFailedModal = (state: AppState) =>
  state.itemBulkUpload.isOpenFailedModal

export const selectApiReturnCode = (state: AppState) =>
  state.itemBulkUpload.returnCode

export const selectApiError = (state: AppState) => state.apiError
